import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchLinkedPhotos,
  fetchPageContentAlbums,
} from 'src/modules/page/pageSlice'

import { NavLink } from 'src/modules/ui'

import PagePhotoList from './PagePhotoList'

import { INSTANCE_TYPE_PHOTO_ALBUM, useTreeLink } from '../app/links'

import { useDispatch, useSelector } from 'react-redux'
import { selectPageContentAlbums } from './pageSlice'
import { LoadingIndicator } from '../ui'
import PageAlbumList from './PageAlbumList'

const PHOTO_PATH = 'media/all'
const ALBUM_PATH = 'media/albums'
const MEDIA_ROOT_PATH = 'media'

const PHOTO_TAB_NUMBER = 0
const ALBUM_TAB_NUMBER = 1

const TabPanel = ({ children, tabIndex, tabNumber }) => {
  if (tabIndex === tabNumber) {
    return <div>{children}</div>
  } else {
    return null
  }
}

const Photos = () => {
  const dispatchFetchLinkedPhotos = useActionDispatcher(fetchLinkedPhotos)
  const dispatchFetchLinkedAlbums = useActionDispatcher(fetchPageContentAlbums)
  const {
    params: { pageType, linkedPageId },
  } = useRouteMatch('/:slug/:pageType/:linkedPageId/')

  const mediaRootRoute = useTreeLink(
    `${pageType}/${linkedPageId}/${MEDIA_ROOT_PATH}`
  )
  const photoRoute = useTreeLink(`${pageType}/${linkedPageId}/${PHOTO_PATH}`)
  const albumRoute = useTreeLink(`${pageType}/${linkedPageId}/${ALBUM_PATH}`)

  const matchMediaRootRoute = useRouteMatch(mediaRootRoute)
  const matchPhotoListRoute = useRouteMatch(photoRoute)
  const matchAlbumRoute = useRouteMatch(albumRoute)

  const handleOnFinishedUploadingPhotos = () => {
    if (tabIndex === PHOTO_TAB_NUMBER) {
      dispatchFetchLinkedPhotos({ target: linkedPageId, page: 0 })
    } else if (tabIndex === ALBUM_TAB_NUMBER) {
      dispatchFetchLinkedAlbums({
        target: linkedPageId,
        page: 0,
        sort: '-published_at',
        type: INSTANCE_TYPE_PHOTO_ALBUM,
      })
    }
  }

  const dispatch = useDispatch()
  const albums = useSelector(selectPageContentAlbums)
  const [loading, setLoading] = React.useState(true)
  const [tabIndex, setTabIndex] = React.useState(PHOTO_TAB_NUMBER)

  useEffect(() => {
    if (matchMediaRootRoute?.isExact && loading) {
      if (albums.results.length > 0) {
        setTabIndex(ALBUM_TAB_NUMBER)
        setLoading(false)
      } else {
        dispatch(
          fetchPageContentAlbums({
            target: linkedPageId,
            page: 0,
            type: INSTANCE_TYPE_PHOTO_ALBUM,
          })
        ).then(response => {
          const results = response?.payload?.results || []
          if (results.length > 0) {
            setTabIndex(ALBUM_TAB_NUMBER)
          } else {
            setTabIndex(PHOTO_TAB_NUMBER)
          }
          setLoading(false)
        })
      }
    } else if (matchPhotoListRoute?.isExact) {
      setTabIndex(PHOTO_TAB_NUMBER)
      setLoading(false)
    } else if (matchAlbumRoute?.isExact) {
      setTabIndex(ALBUM_TAB_NUMBER)
      setLoading(false)
    }
  }, [
    setTabIndex,
    mediaRootRoute,
    matchPhotoListRoute,
    matchAlbumRoute,
    setLoading,
    dispatch,
    linkedPageId,
    albums,
    matchMediaRootRoute,
    loading,
  ])

  if (loading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink
              isActive={() => tabIndex === PHOTO_TAB_NUMBER}
              to={photoRoute}
            >
              All Media
            </NavLink>
            <NavLink
              isActive={() => tabIndex === ALBUM_TAB_NUMBER}
              to={albumRoute}
            >
              Albums
            </NavLink>{' '}
          </Stack>
        </Box>
      </Stack>

      <TabPanel tabIndex={tabIndex} tabNumber={PHOTO_TAB_NUMBER}>
        <PagePhotoList target={linkedPageId} />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} tabNumber={ALBUM_TAB_NUMBER}>
        <PageAlbumList
          onFinishedUploadingPhotos={handleOnFinishedUploadingPhotos}
          target={linkedPageId}
          type={INSTANCE_TYPE_PHOTO_ALBUM}
        />
      </TabPanel>
    </div>
  )
}

export default Photos

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectIndividualById,
  selectNodeDirectory,
} from '../viewer/viewerSlice'
import { AddFactButton } from '../ui/actionButtons'
import CreateUpdateFact from './CreateUpdateFact'
import FactsList from './FactsList'
import { IndividualRelationships } from '../viewer/IndividualRelationships'
import { Box } from '@mui/material'
import { useActionDispatcher } from '../app'
import { fetchLinkedFacts, selectLinkedFacts } from './pageSlice'
import { LoadingIndicator } from '../ui'
import TreeLoadingGuard from '../viewer/TreeLoadingGuard'
import { styled } from '@mui/styles'
export const FactListContext = React.createContext({})

export const DEFAULT_FORM_VALUES = { preferred: false }
export const DEFAULT_EDIT_FORM_VALUES = {
  factId: undefined,
  editModalOpen: false,
  defaultTab: 0,
  spouse: undefined,
  formValues: DEFAULT_FORM_VALUES,
  initialValues: DEFAULT_FORM_VALUES,
}

export const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'flex-end',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

const IndividualRelationshipsSideBar = ({ individual }) => {
  return (
    <Box>
      {individual && (
        <TreeLoadingGuard partialCallKey={individual?.id}>
          <IndividualRelationships individual={individual} />
        </TreeLoadingGuard>
      )}
    </Box>
  )
}

export default function Facts() {
  const { linkedPageId } = useParams()
  const {
    linkedFacts,
    factEditParams,
    setFactEditParams,
    loading,
    individual,
    spouse,
    setSpouse,
    factIndividual,
  } = useLinkedFacts(fetchLinkedFacts, selectLinkedFacts, linkedPageId)

  return (
    <FactListContext.Provider
      value={{
        spouse: spouse,
        setSpouse: setSpouse,
        factEditParams: factEditParams,
        setFactEditParams: setFactEditParams,
      }}
    >
      {loading || !individual ? (
        <LoadingIndicator />
      ) : (
        <>
          <ActionButtons sx={{ justifyContent: 'flex-start' }}>
            <AddFactButton
              onClick={() =>
                setFactEditParams({
                  factId: undefined,
                  editModalOpen: true,
                  defaultTab: 0,
                  formValues: DEFAULT_FORM_VALUES,
                })
              }
            />
          </ActionButtons>
          <Box
            sx={{
              display: 'flex',
              gap: 12,
              justifyContent: 'space-between',
            }}
          >
            <FactsList facts={linkedFacts} individual={individual} />
            <IndividualRelationshipsSideBar individual={individual} />
          </Box>
        </>
      )}

      <CreateUpdateFact
        id={factEditParams.factId}
        open={factEditParams.editModalOpen}
        setFactEditParams={setFactEditParams}
        individual={factIndividual}
        pageIndividual={individual}
        formValues={factEditParams.formValues}
        defaultTab={factEditParams.defaultTab}
        initialValues={factEditParams.initialValues}
        setSpouse={setSpouse}
      />
    </FactListContext.Provider>
  )
}

function useLinkedFacts(fetchLinkedFacts, selectLinkedFacts, linkedPageId) {
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const linkedFacts = useSelector(selectLinkedFacts)
  const nodeDirectory = useSelector(selectNodeDirectory)
  const individual = useSelector(selectIndividualById(linkedPageId))
  const [factEditParams, updateFactEditParams] = useState(
    DEFAULT_EDIT_FORM_VALUES
  )

  const [spouse, setSpouse] = useState(undefined)

  useEffect(() => {
    dispatchFetchLinkedFacts({ target: linkedPageId })
  }, [dispatchFetchLinkedFacts, linkedPageId])

  const loading = dispatchFetchLinkedFacts.status === 'loading'
  let factIndividual = undefined

  const setFactEditParams = ({
    factId,
    editModalOpen,
    defaultTab,
    formValues,
  }) => {
    let newFormValues = formValues
    let initialValues = factEditParams.initialValues

    if (factId !== factEditParams.factId) {
      const fact =
        factId !== undefined
          ? linkedFacts?.find(fact => fact.id === factId)
          : {}

      newFormValues = {
        factType: fact?.factType,
        customFactType: fact?.customFactType,
        fromDateGed: fact?.fromDateGed,
        toDateGed: fact?.toDateGed,
        date: fact?.recordedDatesGed?.[0],
        address: fact?.address,
        preferred: fact?.preferred || false,
        notes: fact?.notes,
        sourcesCitationsList: fact?.sourcesCitationsList || [],
        detail: fact?.detail,
        individualId: fact?.individualId,
      }

      factIndividual = nodeDirectory[fact?.individualId]
      setSpouse(factIndividual)

      initialValues = newFormValues
    }

    updateFactEditParams({
      factId: factId,
      editModalOpen: editModalOpen,
      defaultTab: defaultTab,
      formValues: newFormValues,
      initialValues: initialValues,
    })
  }

  return {
    linkedFacts,
    factEditParams,
    setFactEditParams,
    loading,
    individual,
    factIndividual,
    setSpouse,
    spouse,
  }
}

import { Box } from '@mui/material'
import React from 'react'

import { Typography } from 'src/modules/ui'

const FormHeader = ({ title, subtitle }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{
          mb: 6,
        }}
      >
        <Typography variant="h1" color="primary">
          {title}
        </Typography>
      </Box>
      <Typography
        color="textSecondary"
        variant="subtitle1"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    </Box>
  )
}

export default FormHeader

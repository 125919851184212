import PublicArticle from '../content/PublicArticle'
import { PINNED_ARTICLE_DISPLAY_CONFIG } from '../../page/PinnedArticle'
import { useSelector } from 'react-redux'
import {
  selectPublicPageArticle,
  setPublicPageArticle,
  selectPublicPageItem,
} from './pageSlice'
import { Typography } from '@mui/material'
import { useBuildMapConfiguation } from 'src/modules/map/mapHooks'

const PUBLIC_PINNED_ARTICLE_DISPLAY_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeader: true,
}

const PublicPinnedArticle = ({ id, config }) => {
  const params = { params: { id: id } }
  const fetchedPublicPage = useSelector(selectPublicPageItem)
  const cachedPinnedArticleData = useSelector(selectPublicPageArticle)
  const { mapBlockComponent } = useBuildMapConfiguation(fetchedPublicPage)

  const articleConfig = config ? config : PUBLIC_PINNED_ARTICLE_DISPLAY_CONFIG

  return (
    <>
      {mapBlockComponent}
      {!id ? (
        <Typography>No further information added</Typography>
      ) : (
        <>
          <PublicArticle
            match={params}
            config={articleConfig}
            onArticleLoaded={setPublicPageArticle}
            cachedArticle={cachedPinnedArticleData.article}
          />
        </>
      )}
    </>
  )
}

export default PublicPinnedArticle

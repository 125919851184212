import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  fetchLinkedPageItem,
  resetPageToInitialState,
  selectLinkedPageArticle,
  selectLinkedPageItem,
  setLinkedPageArticle,
} from './pageSlice'
import PinnedArticle, {
  PINNED_ARTICLE_DISPLAY_CONFIG,
  PinnedArticleButtons,
} from './PinnedArticle'

import { Box } from '@mui/material'
import { selectIndividualById } from '../viewer/viewerSlice'
import { isUndefined } from 'lodash'
import { useActionDispatcher } from '../app'
import { useBuildMapConfiguation } from 'src/modules/map/mapHooks'

const LINKED_PINNED_ARTICLE_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeaderAuthor: false,
  showInLineHeaderShare: false,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: true,
  showComments: true,
}

// an item's welcome page
const LinkedPinnedArticle = ({ pageType }) => {
  const { linkedPageId } = useParams()
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchLinkedPageItem)
  const cachedPinnedArticleData = useSelector(selectLinkedPageArticle)
  const { mapBlockComponent } = useBuildMapConfiguation(fetchedLinkedPage)

  // For creation of entities linked to individuals, preselect the
  // family as well:
  const targets = [linkedPageId]
  const individual = useSelector(selectIndividualById(linkedPageId))
  if (!isUndefined(individual)) {
    targets.push(individual.family)
  }

  useEffect(() => {
    const actionParams = {
      pageType: pageType,
      linkedPageId: linkedPageId,
    }

    if (
      !cachedPinnedArticleData.articleId &&
      cachedPinnedArticleData?.loading
    ) {
      dispatchFetchLinkedPageItem(actionParams)
    }
  }, [
    dispatchFetchLinkedPageItem,
    cachedPinnedArticleData.articleId,
    cachedPinnedArticleData?.loading,
    pageType,
    linkedPageId,
  ])

  return (
    <Box>
      {mapBlockComponent}

      <PinnedArticle
        cachedPinnedArticleData={cachedPinnedArticleData}
        onArticleLoaded={setLinkedPageArticle}
        config={LINKED_PINNED_ARTICLE_CONFIG}
        pinTarget={fetchedLinkedPage?.id}
      >
        <>
          <>
            <PinnedArticleButtons
              createButtonText={'Create Description Article'}
              pinButtonText={'Pin an Existing Article'}
              pinTarget={fetchedLinkedPage}
              pinTargetType={pageType}
              onArticleChanged={resetPageToInitialState}
              presetTargets={targets}
              onArticleLoaded={setLinkedPageArticle}
            />
          </>
        </>
      </PinnedArticle>
    </Box>
  )
}

export default LinkedPinnedArticle

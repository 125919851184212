import React, { useEffect } from 'react'
import { titleCase } from 'title-case'
import { useDispatch } from 'react-redux'

import HomeMapList from 'src/modules/home/HomeMapList'
import { setLinkablePageOpen } from 'src/modules/informationRequest/informationRequestSlice'
import { PageTitle, Tabs } from 'src/modules/ui'

import Research from './Research'
import Documents from './Documents'
import Events, { EventsAsSubject } from './Events'
import Photos from './Photos'
import Locations from './Locations'
import Artefacts from './Artefacts'
import Families from './Families'
import LinkedFeed from './LinkedFeed'
import { Box } from '@mui/system'
import LinkedTree from './LinkedTree'
import LineageTree from './LineageTree'
import {
  generateTreeLink,
  NAV_KEY_ARTEFACT,
  NAV_KEY_EVENT,
  NAV_KEY_FEED,
  NAV_KEY_LOCATION,
  NAV_KEY_MEDIA,
  NAV_KEY_RESEARCH,
  NAV_KEY_DOCUMENT,
  NAV_KEY_WELCOME,
  PATH_SEGMENT_ARTEFACT,
  PATH_SEGMENT_EVENT,
  PATH_SEGMENT_EVENT_FOR_SUBJECT,
  PATH_SEGMENT_FOR_INSTANCE_TYPE,
  PATH_SEGMENT_LOCATION,
  PATH_SEGMENT_MEDIA,
  NAV_KEY_FACTS,
  NAV_KEY_PEOPLE,
  PATH_SEGMENT_PEOPLE,
  PATH_SEGMENT_FANS,
  NAV_KEY_MAP,
} from '../app/links'
import LinkedPinnedArticle from './LinkedPinnedArticle'
import IndividualPinnedArticle from './IndividualPinnedArticle'
import FamilyPinnedArticle from './FamilyPinnedArticle'
import Facts from './Facts'
import PagePeople from './PagePeople'

const Page = ({
  editComponent,
  headerComponent,
  backButton,
  routes,
  showTags = true,
  targetId,
  target = {},
  photoUrl,
  subtitle,
  entityTypeTitle,
  title,
  description,
  address,
  locations,
  type,
  fullWidthSubRootContainer,
  handleSelect,
  extraLinksForUploadedMedia,
  lastKnownCustodian,
  families,
  gedcomTestDate,
  author,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setLinkablePageOpen({
        display: title,
        target: targetId,
        instanceType: type,
      })
    )
    return () => dispatch(setLinkablePageOpen())
  }, [dispatch, targetId, title, type])

  return (
    <Box>
      {headerComponent}
      <Tabs
        fullWidthSubRootContainer={fullWidthSubRootContainer}
        routes={routes}
        type={type}
        targetId={targetId}
        title={
          <PageTitle
            {...{
              backButton,
              description,
              entityTypeTitle,
              extraAction: editComponent,
              extraLinksForUploadedMedia,
              families,
              handleSelect,
              lastKnownCustodian,
              photoUrl,
              showTags,
              subtitle,
              targetId,
              target,
              title,
              type,
              gedcomTestDate,
              address,
              locations,
              author,
            }}
          />
        }
      />
    </Box>
  )
}

export default Page

const appRouteComponentMap = {
  facts: Facts,
  research: Research,
  documents: Documents,
  occasions: Events,
  eventsSubject: EventsAsSubject,
  media: Photos,
  places: Locations,
  artefacts: Artefacts,
  families: Families,
  people: PagePeople,
  fans: PagePeople,
  feed: LinkedFeed,
  tree: LinkedTree,
  lineageTree: LineageTree,
  welcome: LinkedPinnedArticle,
  individual_profile: IndividualPinnedArticle,
  family_profile: FamilyPinnedArticle,
  map: HomeMapList,
}

const createRoute =
  ({
    exact,
    routeType,
    labelOverride,
    routePathOverride,
    fullWidth = false,
    navKey,
  }) =>
  ({
    makeDefault,
    pageType,
    linkedPageId,
    localLabelOverride,
    routeComponentMap = appRouteComponentMap,
    treeSlug,
    disabled = false,
  }) => {
    routePathOverride = routePathOverride || routeType

    let path
    let finalRoute
    const linkedPagePath = PATH_SEGMENT_FOR_INSTANCE_TYPE[pageType]

    if (makeDefault) {
      path = generateTreeLink(treeSlug, `${linkedPagePath}/${linkedPageId}`)
      finalRoute = generateTreeLink(
        treeSlug,
        `${linkedPagePath}/:linkedPageId/`
      )
    } else {
      path = generateTreeLink(
        treeSlug,
        `${linkedPagePath}/${linkedPageId}/${routePathOverride}`
      )
      finalRoute = generateTreeLink(
        treeSlug,
        `${linkedPagePath}/:linkedPageId/${routePathOverride}`
      )
    }

    return {
      navKey: navKey,
      navLabel:
        localLabelOverride || labelOverride || `${titleCase(routeType)}`,
      path: path,
      route: finalRoute,
      pageType: pageType,
      component: routeComponentMap[routeType],
      fullWidth: fullWidth,
      exact,
      disabled,
    }
  }

export const getTreeRoute = createRoute({
  exact: true,
  routeType: 'tree',
  fullWidth: true,
})
export const getLineageTreeRoute = createRoute({
  exact: true,
  routeType: 'lineageTree',
  fullWidth: true,
})

export const getWelcomeRoute = createRoute({
  exact: true,
  routeType: 'welcome',
  navKey: NAV_KEY_WELCOME,
})

export const getIndividualProfileRoute = createRoute({
  exact: true,
  routeType: 'individual_profile',
  navKey: NAV_KEY_WELCOME,
})

export const getFamilyProfileRoute = createRoute({
  exact: true,
  routeType: 'family_profile',
  navKey: NAV_KEY_WELCOME,
})

export const getFeedRoute = createRoute({
  exact: true,
  routeType: 'feed',
  navKey: NAV_KEY_FEED,
})
export const getFactsRoute = createRoute({
  exact: true,
  routeType: 'facts',
  labelOverride: 'Facts',
  navKey: NAV_KEY_FACTS,
})
export const getMapsRoute = createRoute({
  exact: true,
  routeType: 'map',
  labelOverride: 'Maps',
  navKey: NAV_KEY_MAP,
})
export const getHistoryRoute = createRoute({
  exact: true,
  routeType: 'research',
  labelOverride: 'Research',
  navKey: NAV_KEY_RESEARCH,
})
export const getDocumentsRoute = createRoute({
  exact: true,
  routeType: 'documents',
  labelOverride: 'Documents',
  navKey: NAV_KEY_DOCUMENT,
})
export const getPhotosRoute = createRoute({
  exact: false,
  routeType: PATH_SEGMENT_MEDIA,
  navKey: NAV_KEY_MEDIA,
})
export const getLocationsRoute = createRoute({
  exact: true,
  routeType: PATH_SEGMENT_LOCATION,
  navKey: NAV_KEY_LOCATION,
})

export const getPeopleRoute = createRoute({
  exact: false,
  routeType: PATH_SEGMENT_PEOPLE,
  navKey: NAV_KEY_PEOPLE,
})

export const getFansRoute = createRoute({
  exact: false,
  routeType: PATH_SEGMENT_FANS,
  navKey: NAV_KEY_PEOPLE,
})
export const getArtefactsRoute = createRoute({
  exact: true,
  routeType: PATH_SEGMENT_ARTEFACT,
  navKey: NAV_KEY_ARTEFACT,
  labelOverride: 'Artifacts',
})
export const getEventsRoute = createRoute({
  exact: true,
  routeType: PATH_SEGMENT_EVENT,
  navKey: NAV_KEY_EVENT,
})
export const getEventsSubjectRoute = createRoute({
  exact: false,
  routeType: PATH_SEGMENT_EVENT_FOR_SUBJECT,
  navKey: NAV_KEY_EVENT,
  labelOverride: 'Occasions',
  routePathOverride: 'occasions',
})

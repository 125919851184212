import { Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { useActionDispatcher, useArchiveFocus } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikPasswordField,
  FormikTextField,
  FIELD_REQUIRED,
  Typography,
} from 'src/modules/ui'

import FormActions from './FormActions'
import FormHeader from './FormHeader'
import { createUser } from './authSlice'
import { checkUserAuthState } from './utils'
import {
  passwordPolicyDescription,
  passwordPolicyRegEx,
  passwordWhitespaceDescription,
  passwordWhitespaceRegEx,
} from './passwordPolicySettings'
import PasswordPolicy from './PasswordPolicy'
import config from '../../config'
import { ACTION_LOGIN, FOCUS_ONE_PLACE_STUDY } from '../app/appConstants'
import FormLink from './FormLink'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'
import { useAppendQueryParams } from '../app'

export const SignUpForm = ({
  handleSubmit,
  isSubmitting,
  onClickGoToLogin,
  values,
  isValid,
  dirty,
}) => {
  const focus = useArchiveFocus()

  const archiveType =
    focus === FOCUS_ONE_PLACE_STUDY ? 'One Place Study' : 'Family Archive'

  return (
    <div>
      <FormHeader
        title="Sign up for free"
        subtitle={`Start your 30-day trial and create your <b>${archiveType}</b> today`}
      />
      <Form>
        <FormikTextField
          fullWidth
          label="Your email"
          margin="normal"
          name="email"
          type="text"
          autoComplete="email"
        />
        <FormikPasswordField
          fullWidth
          label="Your password"
          margin="normal"
          name="password"
          autoComplete="new-password"
        />

        <FormikPasswordField
          fullWidth
          label="Confirm your password"
          margin="normal"
          name="passwordConfirmation"
          autoComplete="new-password"
        />

        <PasswordPolicy
          variant="hint"
          sx={{ display: 'block', paddingTop: '1rem' }}
        />

        <Typography mt={3}>
          By signing up, you agree with our{' '}
          <Link
            href={`${config.publicUrl}/terms-conditions/`}
            target="_blank"
            color="inherit"
            onClick={e =>
              sendEvent(ga4Events.SIGNUP_PAGE_TERMS_AND_CONDITIONS_CLICKED)
            }
          >
            Terms & Conditions{' '}
            <OpenInNewIcon
              fontSize="tiny"
              style={{ verticalAlign: 'text-bottom' }}
            />
          </Link>{' '}
          and{' '}
          <Link
            href={`${config.publicUrl}/privacy-notice/`}
            target="_blank"
            color="inherit"
            onClick={e =>
              sendEvent(ga4Events.SIGNUP_PAGE_PRIVACY_POLICY_CLICKED)
            }
          >
            Privacy Policy{' '}
            <OpenInNewIcon
              fontSize="tiny"
              style={{ verticalAlign: 'text-bottom' }}
            />
          </Link>
        </Typography>
        <FormActions style={{ alignItems: 'center' }}>
          <FormLink
            onClick={e => {
              sendEvent(ga4Events.SIGNUP_PAGE_GO_TO_LOGIN_PAGE_CLICKED)
              onClickGoToLogin({ email: values.email })
            }}
          >
            Go to login page
          </FormLink>

          <Button
            permissionAction={ACTION_LOGIN}
            color="primary"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            size="large"
            type="submit"
            disabled={!(isValid && dirty)}
          >
            Sign Up
          </Button>
        </FormActions>
      </Form>
    </div>
  )
}

const SignUp = ({
  onStateChange,
  onClickGoToLogin,
  prefillEmail,
  ...props
}) => {
  const history = useHistory()
  const dispatchCreateUser = useActionDispatcher(createUser)
  const appendQueryParams = useAppendQueryParams()

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().trim().required(FIELD_REQUIRED),
    password: Yup.string()
      .required(FIELD_REQUIRED)
      .matches(passwordWhitespaceRegEx, passwordWhitespaceDescription)
      .matches(passwordPolicyRegEx, passwordPolicyDescription),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  })

  const initialValues = {
    email: prefillEmail ?? '',
    password: '',
    passwordConfirmation: '',
  }

  const handleSubmit = async ({ email, password }, { setErrors }) => {
    try {
      sendEvent(ga4Events.SIGNUP_PAGE_SUBMITTED)
      const cleanEmail = email.trim()
      await dispatchCreateUser({
        email: cleanEmail,
        password,
      }).unwrap()
      sendEvent(ga4Events.SIGNUP_PAGE_SUBMITTED_OK)
      const user = await Auth.signIn(cleanEmail, password)
      await checkUserAuthState(user, onStateChange)
      // we are currently at /signup and the rest of the auth flow is at /
      history.push(appendQueryParams('/'))
    } catch (err) {
      console.error(`handleSubmit(): caught error ${err}`, err)
      sendEvent(ga4Events.SIGNUP_PAGE_SUBMITTED_ERROR)
      if (
        err.data &&
        err.data['email'] &&
        Array.isArray(err.data['email']) &&
        err.data['email'][0] === 'CustomerUser with this email already exists.'
      ) {
        err.data['email'] = [
          "User with this email address already exists. If this is your email address, try logging in using the 'Go to login page' link below.",
        ]
      }
      setErrors(err.data)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {formik => (
        <SignUpForm
          {...formik}
          {...props}
          isSubmitting={dispatchCreateUser.status === 'loading'}
          onClickGoToLogin={onClickGoToLogin}
        />
      )}
    </Formik>
  )
}

export default SignUp

import React, { useEffect, useState } from 'react'
import Map from './Map'
import { Box } from '@mui/material'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { useSelector } from 'react-redux'
/* 
hook used to build a map component to display on a page from an article or a thing with an address
*/
export const useBuildMapConfiguation = itemWithAnAddress => {
  const [currentMap, setCurrentMap] = useState(null)

  const buildMapConfiguration = item => {
    // console.debug(
    //   `LinkedPinnedArticle.buildMapConfiguration(): called with item:`,
    //   item
    // )
    if (item && item.address) {
      const mapParams = { title: `Map showing ${item.title}` }

      mapParams.mapLinks = [
        {
          id: item.id,
          instanceType: 'address',
          title: item.title,
          target: {
            id: item.address.id,
            //latiGed: item.address.latiGed,
            //longGed: item.address.longGed,
            lati: item.address.lati,
            long: item.address.long,
            photo: item.photo?.fileThumbnail,
            freeText: item.address.freeText,
          },
        },
      ]

      // console.debug(
      //   `LinkedPinnedArticle.buildMapConfiguration(): setting currentMap to:`,
      //   mapParams
      // )

      setCurrentMap(mapParams)
    } else {
      setCurrentMap(null)
    }
  }

  useEffect(() => buildMapConfiguration(itemWithAnAddress), [itemWithAnAddress])

  const mapBlockComponent = currentMap && (
    <Box
      // a spacer the map can appear in
      style={{
        width: '100%',
        //height: '400px',
        border: 'solid 1px',
        display: 'flex', // this and below just to center the 'Waiting for places with addresses' text
        textAlign: 'center',
        flexDirection: 'column',
      }}
      sx={{ mb: 4 }}
    >
      <Box>
        <Map
          currentMap={currentMap}
          clickableMarkers={false} // only 1 marker so disable marker info panel
          inlineMapHeight={400}
          //singleCoordZoomLevel={14}
          markerInfoPanelEnabled={true} // only 1 marker but it's nice to know what it is
          initialInteractive={false}
          preferAddressAsMarkerPopup={true}
          fullwindowOnMapClick={true}
          interactiveWhenFullWindow={true}
          allowThreeD={false}
          allowThreeDWhenFullWindow={true}
          allowEditButton={false}
          //treeSlug={treeSlug}
        />
      </Box>
    </Box>
  )

  return {
    currentMap,
    buildMapConfiguration,
    mapBlockComponent,
  }
}

/*
  used when you want to display multiple maps on a page
 */
export const useBuildMultipleMapConfiguration = linkedLocations => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const debug = false

  const [currentMap, setCurrentMap] = useState(null)

  useEffect(() => {
    const buildMapConfiguration = locations => {
      if (debug)
        console.debug(
          `buildMapConfiguration(): called with locations:`,
          locations
        )
      if (locations && locations.results && locations.results.length > 0) {
        const mapParams = {
          title: 'Map of places tagged with current location',
        }

        let linkId = 1
        const mapLinks = locations.results.flatMap(location => {
          if (location.address) {
            // MapEdit.WeAreMapFeature
            return {
              id: linkId++, //used for repeating element keys
              instanceType: 'location',
              title: location.title,
              target: {
                id: location.id,
                lati: location.address.lati,
                long: location.address.long,
                photo: location.photo,
              },
            }
          }
          return []
        })

        if (mapLinks && mapLinks.length > 0) {
          mapParams.mapLinks = mapLinks
          if (debug)
            console.debug(
              `buildMapConfiguration(): setting currentMap to:`,
              mapParams
            )

          setCurrentMap(mapParams)
        } else {
          setCurrentMap(null)
        }
      } else {
        if (debug)
          console.debug(
            `buildMapConfiguration(): no locations - setting currentMap to null`
          )
        setCurrentMap(null)
      }
    }

    buildMapConfiguration(linkedLocations)
  }, [linkedLocations, debug])

  const mapBlockComponent = currentMap && (
    <Map
      currentMap={currentMap}
      isArticle={false}
      flyOnMarkerClick={false}
      inlineMapHeight={400}
      maxSidepanelTitleLines={2}
      sidePanelShowLatLong={false}
      initialInteractive={false}
      fullwindowOnMapClick={true}
      interactiveWhenFullWindow={true}
      treeSlug={treeSlug} // used when linking in the side panel
      //markerItemType={INSTANCE_TYPE_LOCATION}
      allowThreeD={false}
      allowThreeDWhenFullWindow={true}
      allowEditButton={false}
      useDefaultMarkerIconsOnMap={true}
    />
  )

  return {
    currentMap,
    mapBlockComponent,
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_LOCATION } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import {
  fetchLinkedPageLocations,
  selectLinkedPageLocations,
} from './pageSlice'

import { Box } from '@mui/material'

import { useBuildMultipleMapConfiguration } from 'src/modules/map/mapHooks'

// used inside an item's places tab
const Locations = () => {
  const { linkedPageId } = useParams()
  const linkedLocations = useSelector(selectLinkedPageLocations)
  const { mapBlockComponent } =
    useBuildMultipleMapConfiguration(linkedLocations)

  return (
    <>
      <LinkedPageList
        action={fetchLinkedPageLocations}
        fetchPageSize={999}
        selector={selectLinkedPageLocations}
        target={linkedPageId}
        type={INSTANCE_TYPE_LOCATION}
        map={
          <Box
            style={{
              width: '100%',
              display: 'flex', // this and below just to center the 'Waiting for places with addresses' text
              textAlign: 'center',
              flexDirection: 'column',
            }}
          >
            {mapBlockComponent}
          </Box>
        }
      />
    </>
  )
}

export default Locations
